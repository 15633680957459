import { Box, Typography, Stack, IconButton } from "@mui/material";
import { Android, Apple } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface Props {
  text?: string;
}

// Determines colour for sub link in footer
export const linkStyle = {
  textDecoration: "none",
  color: "white",
  fontSize: 15,
  paddingBottom: "5px",
  cursor: 'pointer'
};

export const HeaderText = ({ text }: Props) => {
  return (
    <Typography
      variant="h5"
      color="white"
      textAlign="center"
      sx={{
        textDecoration: "underline",
        textDecorationColor: "white",
        textUnderlineOffset: "10px",
        fontWeight: "bold",
        fontSize: "1rem",
      }}
    >
      {text ?? "Default"}
    </Typography>
  );
};

export const AccountSubText = ({ text }: Props) => {
  return (
    <Typography
      sx={{ fontStyle: "italic", fontWeight: 500, fontSize: 14 }}
      color="white"
    >
      {text}
    </Typography>
  );
};

export const FooterHeaderText = ({ children }: any) => {
  return (
    <Typography color="white" sx={{ fontWeight: 600, fontSize: 20 }}>
      {children}
    </Typography>
  );
};

export const FooterLinkText = ({ children, to }: any) => {
  return (
    <Link style={linkStyle} to={to}>
      {children}
    </Link>
  );
};

// TODO: Create download app header
export const DownloadHeader = () => {
  const { t, i18n } = useTranslation();
  return (
    <Box
      sx={{
        backgroundColor: "black",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      py={1}
    >
      <Stack
        direction="row"
        spacing={2}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Typography textAlign="center" variant="subtitle2" color="#fff">
          {t("Download our app.1")}
        </Typography>
        <IconButton
          onClick={() =>
            window.open(
              "https://play.google.com/store/apps/details?id=com.fabric_connector.market.app",
              "_blank",
              "noopener noreferrer"
            )
          }
        >
          <Android sx={{ fontSize: 32, color: "white" }} />
        </IconButton>
        <IconButton
          onClick={() =>
            window.open(
              "https://apps.apple.com/us/app/fabric-connector/id6446318583",
              "_blank",
              "noopener noreferrer"
            )
          }
        >
          <Apple sx={{ fontSize: 32, color: "white" }} />
        </IconButton>
      </Stack>
    </Box>
  );
};
