// MUI
import { Modal, Box, Typography, FormControl } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { TextField } from "formik-mui";

// Form
import * as yup from "yup";
import { Field, Form, Formik } from "formik";

// Snackbar
import { useSnackbar } from "notistack";

// Axios
import axiosClient from "../api/axiosClient";

interface ModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: '70%', lg: '500px' },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const defaultValues = {
  name: "",
  email: "",
  message: "",
};

const ContactSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  message: yup.string().required("Message is required"),
});

const ContactModal = ({ isOpen, handleClose }: ModalProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (values: {
    name: string;
    email: string;
    message: string;
  }) => {
    try {
      const response = await axiosClient.post("/api/contact", {
        name: values.name,
        email: values.email,
        message: values.message,
      });

      if (response.status === 200) {
        enqueueSnackbar("Message sent successfully", {
          variant: "success",
          preventDuplicate: true,
        });
      } else {
        enqueueSnackbar("Failed to send message. Please try again.", {
          variant: "error",
          preventDuplicate: true,
        });
      }
    } catch (error) {
      console.error("Error sending message:", error);
      enqueueSnackbar("An error occurred while sending your message.", {
        variant: "error",
        preventDuplicate: true,
      });
    } finally {
      handleClose();
    }
  };
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h5" gutterBottom>
          Contact us
        </Typography>

        <Formik
          validationSchema={ContactSchema}
          initialValues={defaultValues}
          onSubmit={handleSubmit}
        >
          {({ values, errors, isSubmitting, isValidating, submitForm }) => (
            <Form>
              <FormControl fullWidth>
                <Field
                  component={TextField}
                  name="name"
                  type="text"
                  label="Name"
                  margin="normal"
                />
              </FormControl>
              <FormControl fullWidth>
                <Field
                  component={TextField}
                  name="email"
                  type="email"
                  label="Email"
                  margin="normal"
                />
              </FormControl>
              <FormControl fullWidth>
                <Field
                  component={TextField}
                  name="message"
                  type="text"
                  label="Message"
                  margin="normal"
                  multiline
                  rows={6}
                />
              </FormControl>
              <LoadingButton
                variant="contained"
                fullWidth
                size="large"
                loading={isSubmitting || isValidating}
                sx={{
                  marginTop: "1rem",
                  backgroundColor: "primary.main",
                  color: "#fff",
                }}
                type="submit"
              >
                Submit
              </LoadingButton>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default ContactModal;
