// Design system imports
import { Box, Typography, Stack } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

// Package imports
import { motion } from 'framer-motion'
import { PopupButton } from '@typeform/embed-react'

// Project imports
import Button from '../Input/Button'
import heroBlobs from '../../assets/images/blob.png'
import useReducedMotion from '../../hooks/useReducedMotion'
import { theme } from '../../theme'

const HeroSection = () => {
  const prefersReducedMotion = useReducedMotion()
  const commonAnimationProps = prefersReducedMotion
    ? {
      whileHover: {
        opacity: 0.8,
        transition: { duration: 0.2 },
        backgroundColor: theme.palette.error.main,
      },
      whileTap: { opacity: 0.6, transition: { duration: 0.2 } },
    }
    : {
      whileHover: {
        scale: 1.1,
        transition: { duration: 0.4 },
        backgroundColor: theme.palette.error.main,
      },
      whileTap: { y: 2.5, scale: 0.9, transition: { duration: 0.2 } },
    }

  const pulseAnimation = {
    initial: { opacity: 0.5 },
    animate: {
      opacity: [0.5, 1, 0.5],
      transition: {
        duration: 8,
        ease: 'easeInOut',
        repeat: Infinity,
        repeatType: 'loop',
        times: [0, 0.5, 1],
      } as const,
    },
  }

  return (
    <Stack component={'section'} paddingBottom={12}>
      <Stack justifyContent={'center'} gap={{ xs: 8, md: 4 }}>
        {/* Heading */}
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent={'space-evenly'}
          alignItems={'center'}
          gap={{ xs: 4, md: 8 }}
          minHeight={{ xs: 'calc(100vh - 124px)', md: 'calc(75vh - 124px)' }}
        >
          <Box
            position={'relative'}
            width={{ xs: '100%', md: 'calc(50% - 64px)' }}
            sx={{
              aspectRatio: '1/1',
            }}
          >
            <motion.img
              width="66%"
              src={heroBlobs}
              alt={'Fabric Connector logo'}
              initial={'initial'}
              animate={'animate'}
              variants={pulseAnimation}
              style={{
                position: 'absolute',
                top: '0',
                right: 0,
              }}
            />
            <motion.img
              width="66%"
              src={heroBlobs}
              alt={'Fabric Connector logo'}
              initial={'initial'}
              animate={'animate'}
              variants={{
                ...pulseAnimation,
                animate: {
                  ...pulseAnimation.animate,
                  transition: {
                    ...pulseAnimation.animate.transition,
                    delay: 2,
                  },
                },
              }}
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
              }}
            />
          </Box>

          <Typography
            variant={'h4'}
            component={'h1'}
            zIndex={10}
            fontWeight={'light'}
            lineHeight={1.6}
            width={{ xs: '100%', md: '50%' }}
          >
            Our mission is to cut down pollution in the textile industry by sourcing circular and regenerative fabrics.
          </Typography>
        </Stack>

        {/* CTA's */}
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent={'center'}
          alignItems={'center'}
          gap={{ xs: 8, md: 24 }}
        >
          <Stack justifyContent={'center'} alignItems={'center'} gap={4} maxWidth={'24ch'}>
            <Typography
              display={'block'}
              variant={'h5'}
              lineHeight={1.6}
              textAlign={'center'}
              sx={{
                textWrap: 'balance',
              }}
            >
              I'm looking for a specific fabric.
            </Typography>
            {/* Use for link instead of typeform popup */}
            {/* <Button href={'./contact'} variant={'contained'} showArrow>
              Connect
            </Button> */}
            <motion.div
              {...commonAnimationProps}
              style={{
                width: 'fit-content',
                borderRadius: 50,
                backgroundColor: theme.palette.primary.main,
                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
              }}
            >
              <PopupButton
                id="WhZIr3mz"
                style={{
                  border: 'none',
                  backgroundColor: 'transparent',
                  width: 'fit-content',
                  padding: '12px 24px',
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  color: 'white',
                  cursor: 'pointer',
                  fontFamily: 'Poppins, sans-serif',
                }}
                className="typeform-button"
              >
                Connect
                <ArrowForwardIosIcon sx={{ ml: 1, fontSize: 10 }} />
              </PopupButton>
            </motion.div>
          </Stack>
          <Stack justifyContent={'center'} alignItems={'center'} gap={4} maxWidth={'24ch'}>
            <Typography
              display={'block'}
              variant="h5"
              lineHeight={1.6}
              textAlign={'center'}
              sx={{
                textWrap: 'balance',
              }}
            >
              I'd love to browse your collection.
            </Typography>
            <Button href={'/search-page'} variant={'contained'} showArrow>
              Browse
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default HeroSection
