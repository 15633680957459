import { Upload } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Field } from "formik";
import { Select, TextField } from "formik-mui";
import { FormikHelpers, FormikStep, FormikStepper } from "formik-stepper";
import "formik-stepper/dist/style.css";
import { MRT_Row } from "material-react-table";
import { DropzoneDialog } from "mui-file-dropzone";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import axiosClient from "../../api/axiosClient";
import { useAuth } from "../../contexts/AuthContext";

import {
  categoriesList,
  colorsList,
  compositionList,
  countries,
  currencies,
  designsList,
  fiberTypes,
  materialsList,
  patternList,
  usageList,
} from "../../utils/FilterEngine";
import { ProductFormSchema } from "../schemas";
import UploadStepper from "../UploadStepper";
import { resizeFile } from "../../utils/Uploading";
import { MAX_PRODUCT_IMAGE_SIZE } from "../constants";
import UploadDialog from "../Dialog/UploadDialog";

// TODO: Customize for table
// TODO: Fix to align with model
// TODO: Update to match AddProduct form

export interface UploadProps {
  row: MRT_Row<SKU>;
  product: SKU;
  tableData: SKU[];
  setTableData: (tableData: SKU[]) => void;
}

export const UploadProduct = ({
  product,
  row,
  tableData,
  setTableData,
}: UploadProps) => {
  const [open, setOpen] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [openStepper, setOpenStepper] = useState(false);
  const getTutorialSeen = () => localStorage.getItem("tutorial");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (
    event: {},
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (reason === "backdropClick") {
    } else {
      setOpen(false);
    }
  };

  const handleCloseStepper = () => {
    setOpenStepper(false);
  };
  const [images, setImages] = useState<File[]>([]);
  const [initialImages, setInitialImages] = useState<File[]>([]);
  const [b64Images, setB64Images] = useState<FCImage[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { auth, organisation, globalFilters } = useAuth();
  const onSubmit = async (
    values: any,
    { setSubmitting }: FormikHelpers<any>
  ) => {
    const data = {
      title: values.title,
      description: values.description,
      type: values.type,
      category: values.category,
      options: {
        colors: values.colors,
        patterns: values.patterns,
        length: values.length,
        weight: values.weight,
        price: values.price,
        width: values.width,
        quantity: 0,
        countryCode: "NL",
        currency: values.currency,
        fibers: values.fibers,
        compositions: values.compositions,
        materials: values.materials,
        usages: values.usages,
        designs: values.designs,
      },
      images: b64Images,
    };

    axiosClient
      .post(`api/organisation/${organisation}/listing/create`, data)
      .then((res) => {
        enqueueSnackbar("Succesfully uploaded product", {
          variant: "success",
          preventDuplicate: true,
        });
        tableData[row.index] = {
          Title: values.title,
          Description: values.description,
          Type: values.type,
          Colors: values.colors.join(","),
          Materials: values.materials.join(","),
          Patterns: values.patterns.join(","),
          Length: values.length,
          Compositions: values.compositions.join(","),
          CountryCode: values.countryCode,
          Currency: values.currency,
          Fibers: values.fibers.join(","),
          Weight: values.weight,
          Width: values.width,
          Price: values.price,
          Usages: values.usages.join(","),
          Designs: values.designs.join(","),
          Category: values.category,
        };
        setTableData([...tableData]);
        setOpen(false);
      })
      .catch((err) => {
        enqueueSnackbar("Failed to upload product", {
          variant: "error",
          preventDuplicate: true,
        });
      });
  };
  const convert2base64 = async (files: any) => {
    try {
      for (let i = 0; i < files.length; i++) {
        const image = await resizeFile(files[i]);
        b64Images.push({
          fileContentBase64: image,
          fileName: `image ${i}`,
        });
      }
    } catch (error) {}
  };
  useEffect(() => {}, [b64Images]);

  return (
    <>
      <Tooltip arrow placement="right" title="Validate and Upload">
        <Stack>
          <IconButton onClick={handleClickOpen} aria-label="Upload product">
            <Upload color="primary" />
          </IconButton>
        </Stack>
      </Tooltip>

      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogContent>
          <Button
            sx={{ maxWidth: 200 }}
            variant="contained"
            size="medium"
            color="secondary"
            onClick={() => {
              setOpen(false);
            }}
          >
            Exit
          </Button>
          <Card sx={{ maxWidth: 2000 }} elevation={0}>
            <CardContent>
              <FormikStepper
                /// Accept all Formik props
                onSubmit={onSubmit} /// onSubmit Function
                validationSchema={ProductFormSchema}
                initialValues={{
                  title: product.Title ?? "",
                  description: product.Description ?? "",
                  type: product.Type ?? "",
                  category: product.Category ?? "",
                  colors: product?.Colors?.split(/,\s*/) ?? [],
                  materials: product?.Materials?.split(/,\s*/) ?? [],
                  currency: product?.Currency ?? "EUR",
                  length: product?.Length ?? 0,
                  weight: product?.Weight ?? 0,
                  price: product?.Price ?? 0,
                  width: product?.Width ?? 0,
                  countryCode: product?.CountryCode ?? "",
                  patterns: product?.Patterns?.split(",") ?? [],
                  compositions: product?.Compositions?.split(/,\s*/) ?? [],
                  fibers: product?.Fibers?.split(/,\s*/) ?? [],
                  usages: product?.Usages?.split(/,\s*/) ?? [],
                  designs: product?.Designs?.split(/,\s*/) ?? [],
                }}
                nextButton={{
                  label: "Next Step",
                  style: { background: "#cf6631" },
                }}
                prevButton={{ label: "Back", style: { background: "#cf6631" } }}
                submitButton={{
                  label: "Upload",
                  style: { background: "blue" },
                }}
              >
                {/*  First Step */}
                <FormikStep label="Add product" circleColor="#6f42c1">
                  <Divider sx={{ my: 2 }} />
                  {/* TODO:Translation/code */}
                  <Typography>Title*</Typography>
                  <Divider sx={{ my: 2 }} />
                  <Field
                    name="title"
                    component={TextField}
                    multiline
                    rows={4}
                    fullWidth
                    helperText="For example: 60m of Silk fabric"
                  />
                  <Divider sx={{ my: 2 }} />
                  {/* TODO:Translation/code */}
                  <Typography>Describe your product*</Typography>
                  <Divider sx={{ my: 2 }} />
                  <Field
                    name="description"
                    component={TextField}
                    multiline
                    rows={4}
                    fullWidth
                    helperText="For example: Left-over, new fabric, pink silk fabric"
                  />
                  <Divider sx={{ my: 2 }} />
                  {/* TODO:Translation/code */}
                  <Field
                    formControl={{ fullWidth: true, required: true }}
                    component={Select}
                    formHelperText={{ children: "Select 1 or more colors" }}
                    multiple
                    id="colors"
                    name="colors"
                    labelId="color-simple"
                    label="Color*"
                  >
                    {globalFilters!.colors.map((color: any) => (
                      <MenuItem key={color} value={color}>
                        {color}
                      </MenuItem>
                    ))}
                  </Field>

                  <Divider sx={{ my: 2 }} />
                  {/* TODO:Translation/code */}
                  <Field
                    formControl={{ fullWidth: true, required: true }}
                    formHelperText={{
                      children: "Select 1 or more materials",
                    }}
                    component={Select}
                    multiple
                    id="materials"
                    name="materials"
                    labelId="materials-simple"
                    label="Materials*"
                  >
                    {globalFilters!.materials.map((material: any) => (
                      <MenuItem key={material} value={material}>
                        {material}
                      </MenuItem>
                    ))}
                  </Field>

                  <Divider sx={{ my: 2 }} />
                  {/* TODO:Translation/code */}
                  <Field
                    formControl={{ fullWidth: true }}
                    component={Select}
                    id="category"
                    name="category"
                    label={"Category*"}
                  >
                    {globalFilters!.categories.map((category: any) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </Field>
                </FormikStep>
                {/* Second Step */}
                <FormikStep
                  label="Additional information"
                  circleColor="#6f42c1"
                >
                  <Divider sx={{ my: 2 }} />
                  {/* TODO:Translation/code */}
                  <Typography textAlign="center">
                    Add a Maximum of 5 photos
                  </Typography>
                  <Divider sx={{ my: 2 }} />

                  <Divider sx={{ my: 2 }} />
                  {/* TODO:Translation/code */}
                  <Typography>Description of fabric contents</Typography>
                  <Divider sx={{ my: 2 }} />
                  {/* TODO:Translation/code */}
                  <Field
                    formControl={{ fullWidth: true, required: true }}
                    component={Select}
                    formHelperText={{
                      children: "Select fiber type(s) used in your fabric",
                    }}
                    multiple
                    name="fibers"
                    id="fibers"
                    labelId="fiber-simple"
                    label="Fiber"
                  >
                    {globalFilters!.fibers.map((fiber: any) => (
                      <MenuItem key={fiber} value={fiber}>
                        {fiber}
                      </MenuItem>
                    ))}
                  </Field>
                  <Divider sx={{ my: 2 }} />
                  {/* TODO:Translation/code */}
                  <Field
                    formControl={{ fullWidth: true, required: true }}
                    component={Select}
                    formHelperText={{
                      children: "Select usage(s) of your fabric",
                    }}
                    name="usages"
                    multiple
                    id="usages"
                    labelId="usage-simple"
                    label="Usage"
                  >
                    {globalFilters!.usages.map((use: any) => (
                      <MenuItem key={use} value={use}>
                        {use}
                      </MenuItem>
                    ))}
                  </Field>

                  <Divider sx={{ my: 2 }} />
                  {/* TODO:Translation/code */}
                  <Field
                    formControl={{ fullWidth: true, required: true }}
                    component={Select}
                    formHelperText={{
                      children: "Select composition(s) used in your fabric",
                    }}
                    name="compositions"
                    id="compositions"
                    multiple
                    labelId="composition-simple"
                    label="Composition"
                  >
                    {globalFilters!.compositions.map((composition: any) => (
                      <MenuItem key={composition} value={composition}>
                        {composition}
                      </MenuItem>
                    ))}
                  </Field>

                  <Divider sx={{ my: 2 }} />
                  {/* TODO:Translation/code */}
                  <Field
                    formControl={{ fullWidth: true }}
                    component={Select}
                    formHelperText={{
                      children: "Select design(s) found in your fabric",
                    }}
                    name="designs"
                    id="designs"
                    multiple
                    labelId="designs-simple"
                    label="Design"
                  >
                    {globalFilters!.designs.map((design: any) => (
                      <MenuItem key={design} value={design}>
                        {design}
                      </MenuItem>
                    ))}
                  </Field>
                  <Divider sx={{ my: 2 }} />
                  {/* TODO:Translation/code */}
                  <Field
                    formControl={{ fullWidth: true }}
                    component={Select}
                    formHelperText={{
                      children: "Select pattern(s) found in your fabric",
                    }}
                    multiple
                    id="patterns"
                    name="patterns"
                    labelId="colour-simple"
                    label="Pattern"
                  >
                    {globalFilters!.patterns.map((pattern: any) => (
                      <MenuItem key={pattern} value={pattern}>
                        {pattern}
                      </MenuItem>
                    ))}
                  </Field>
                  <Divider sx={{ my: 2 }} />
                  {/* TODO:Translation/code */}
                  <Field
                    formControl={{ fullWidth: true, required: true }}
                    component={Select}
                    formHelperText={{
                      children: "Select the country of origin of your fabric",
                    }}
                    id="countryCode"
                    name="countryCode"
                    labelId="colour-simple"
                    label="Country of Origin"
                  >
                    {countries.map((country) => (
                      <MenuItem key={country.label} value={country.value}>
                        {country.label}
                      </MenuItem>
                    ))}
                  </Field>
                  <Divider sx={{ my: 2 }} />
                  {/* TODO:Translation/code */}
                  <Field
                    name="length"
                    component={TextField}
                    type="number"
                    fullWidth
                    helperText="Select a length in meters"
                  />
                  <Divider sx={{ my: 2 }} />
                  {/* TODO:Translation/code */}
                  <Field
                    name="weight"
                    component={TextField}
                    type="number"
                    fullWidth
                    helperText="Select a weight in g/m2"
                  />
                  <Divider sx={{ my: 2 }} />
                  {/* TODO:Translation/code */}
                  <Field
                    name="width"
                    component={TextField}
                    type="number"
                    fullWidth
                    helperText="Select a width in centimeters"
                  />
                  <Divider sx={{ my: 2 }} />

                  {/* TODO:Translation/code */}
                  <Field
                    name="price"
                    component={TextField}
                    type="number"
                    fullWidth
                    helperText="Specify the price per meter"
                  />
                  <Divider sx={{ my: 2 }} />
                  <Field
                    formControl={{ fullWidth: true }}
                    component={Select}
                    formHelperText={{
                      children: "Select currency",
                    }}
                    id="currency"
                    name="currency"
                    labelId="currency"
                    label="Currency"
                  >
                    {currencies.map((currency) => (
                      <MenuItem key={currency.value} value={currency.value}>
                        {currency.label}
                      </MenuItem>
                    ))}
                  </Field>

                  <Divider sx={{ my: 2 }} />
                  <Stack
                    direction="row"
                    sx={{ width: "100%" }}
                    justifyContent="space-around"
                  >
                    <Stack>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setB64Images([]);
                          setInitialImages([]);
                          setOpenUpload(true);
                          getTutorialSeen();
                          if (getTutorialSeen() === null) {
                            setTimeout(() => {
                              setOpenStepper(true);
                            }, 100);
                            localStorage.setItem("tutorial", "true");
                          }
                        }}
                      >
                        Upload Photos
                      </Button>
                      <Typography>{b64Images.length} files added</Typography>
                    </Stack>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setOpenStepper(true);
                      }}
                    >
                      Need help?
                    </Button>
                  </Stack>
                </FormikStep>
              </FormikStepper>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
      <UploadStepper onClose={handleCloseStepper} open={openStepper} />
      <UploadDialog
        openUpload={openUpload}
        setB64Images={setB64Images}
        setOpenUpload={setOpenUpload}
      />
    </>
  );
};
