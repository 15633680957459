import { useState } from "react";

// Design system imports
import { LinkedIn, Instagram } from "@mui/icons-material";
import { Grid, IconButton, Link, Stack } from "@mui/material";
import Box from "@mui/material/Box";

// Project imports
import { aboutUrl, contactUsUrl } from "../../utils/Strings/Socials";
import {
  FooterHeaderText,
  FooterLinkText,
  linkStyle,
} from "../Text/TextVariants";

// Components
import ContactModal from "../Contact";

function StickyFooter() {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const openContactModal = () => {
    setIsContactModalOpen(true);
  };

  const closeContactModal = () => {
    setIsContactModalOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "10vh",
          minWidth: "100vw",
        }}
      >
        <Box
          component="footer"
          sx={{
            mt: "auto",
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Stack sx={{ width: "100vw", backgroundColor: "black", py: 4 }}>
            <Grid
              sx={{ paddingX: "50px" }}
              direction={{ xs: "row" }}
              spacing={{ xs: 2, md: 4 }}
              p={1}
              justifyContent="start"
              alignItems="start"
              container
            >
              <Grid item xs={6} md={2}>
                <Stack spacing={1}>
                  <FooterHeaderText>Buyers</FooterHeaderText>

                  <Stack>
                    <FooterLinkText to={"/how-it-works#buyer"}>
                      How does it work?
                    </FooterLinkText>
                    <FooterLinkText to={"/terms-and-conditions"}>
                      Terms & conditions
                    </FooterLinkText>
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={6} md={2}>
                <Stack spacing={1}>
                  <FooterHeaderText>Suppliers</FooterHeaderText>

                  <Stack>
                    <FooterLinkText to={"/how-it-works#supplier"}>
                      How does it work?
                    </FooterLinkText>
                    <FooterLinkText to={"/terms-and-conditions"}>
                      Terms & conditions
                    </FooterLinkText>
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={6} md={2}>
                <Stack spacing={1}>
                  <FooterHeaderText>Help</FooterHeaderText>

                  <Stack>
                    <Link sx={linkStyle} onClick={openContactModal}>
                      Contact us
                    </Link>
                    <FooterLinkText to={"/faq"}>FAQ</FooterLinkText>
                    <FooterLinkText to={"/privacy"}>
                      Privacy Statement
                    </FooterLinkText>
                  </Stack>
                </Stack>
              </Grid>
              {/* <Grid
              item
              xs={6}
              md={2}
            >
              <Stack spacing={1}>
                <FooterHeaderText>Become a partner</FooterHeaderText>

                <Stack>
                  <Link
                    sx={linkStyle}
                    href={contactUsUrl}
                  >
                    Collaborate with us
                  </Link>
                  <Link
                    sx={linkStyle}
                    href={contactUsUrl}
                  >
                    Advertisement
                  </Link>
                </Stack>
              </Stack>
            </Grid> */}

              <Grid item xs={6} md={2}>
                <Stack spacing={1}>
                  <FooterHeaderText>About Fabric Connector</FooterHeaderText>

                  <Stack>
                    <Link sx={linkStyle} href={aboutUrl}>
                      About us
                    </Link>
                    <Link sx={linkStyle} href="/blog">
                      Blog
                    </Link>
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={6} md={2}>
                <Stack spacing={2}>
                  <FooterHeaderText>Socials</FooterHeaderText>

                  <Stack direction="row">
                    {/* <LinkedIn /> */}
                    <IconButton
                      href="https://www.linkedin.com/company/the-fabric-connector"
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ p: 0 }}
                    >
                      <LinkedIn sx={{ color: "white", fontSize: 40 }} />
                    </IconButton>
                    {/* <Instagram /> */}
                    <IconButton
                      href="https://www.instagram.com/fabricconnector"
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ p: 0 }}
                    >
                      <Instagram sx={{ color: "white", fontSize: 40 }} />
                    </IconButton>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>

            {/* <Copyright /> */}
          </Stack>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "center",
              marginLeft: "20px",
              p: 1,
              borderBottom: "1px solid #dfe0df",
              height: "40px",
              fontFamily: "Poppins",
            }}
          >
            © 2024 THE FABRIC CONNECTOR
          </Box>
        </Box>
      </Box>

      <ContactModal
        isOpen={isContactModalOpen}
        handleClose={closeContactModal}
      />
    </>
  );
}

export default StickyFooter;
