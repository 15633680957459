// Design system imports
import { Box, Container, Grid, Typography } from "@mui/material";

// Project imports
import partnerLogo1 from "../../assets/images/partner1.png";
import partnerLogo2 from "../../assets/images/partner2.png";
import partnerLogo3 from "../../assets/images/partner3.png";
import partnerLogo4 from "../../assets/images/partner4.png";
import partnerLogo5 from "../../assets/images/partner6.png";
import partnerLogo6 from "../../assets/images/partner7.png";
import partnerLogo7 from "../../assets/images/partner8.png";
import partnerLogo8 from "../../assets/images/partner9.png";

const ClientSection = () => {
  const supplierLogos = [
    partnerLogo1,
    partnerLogo2,
    partnerLogo3,
    partnerLogo4,
    partnerLogo5,
    partnerLogo6,
    partnerLogo7,
    partnerLogo8,
  ]

  return (
    <Box
      component="section"
      sx={{ px: { xs: 2, lg: 0 } }}
    >
      <Container
        maxWidth="xl"
        sx={{
          padding: "10vh 0",
          display: "flex",
          flexDirection: "column",
          gap: 8,
        }}
      >
        <Typography
          variant="h4"
          component="h2"
          sx={{ fontWeight: "bold", lineHeight: 1.6 }}
        >
          Trusted by
        </Typography>
        <Grid container spacing={8} justifyContent={"center"} alignItems={"center"}>
          {supplierLogos.map((logo, i) => (
            <Grid item xs={6} md={3} key={i}>
              <Box component="img" src={logo} alt={`logo-${i}`} style={{ width: '100%' }} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default ClientSection;
