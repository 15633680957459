import Button from "@mui/material/Button";
import { SxProps, Theme } from "@mui/material/styles";

interface LinkTextProps {
  href: string;
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}

export default function LinkText({
  href,
  children,
  sx = [], // Default to an empty object
}: LinkTextProps) {
  return (
    <Button
      sx={[
        {
          width: "max-content",
          color: "black",
          fontFamily: "Poppins",
          fontWeight: "bold",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      href={href}
    >
      {children}
    </Button>
  );
}
//color: "black", fontFamily: "Poppins", fontWeight: "bold" sx
