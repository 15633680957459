import { Box, Stack } from "@mui/material";
import { DownloadHeader, HeaderText } from "../components";
import SKUTable from "../components/Table/SKUTable";

const BulkAdd = () => {
  return (
    <Box sx={{ height: "100%" }}>
      {/* <DownloadHeader /> */}
      <Stack
        sx={{ height: "100%", minHeight: "100vh" }}
        pt={4}
        spacing={12}
        direction="column"
        alignItems="center"
        // justifyContent="center"
      >
        <HeaderText text="Bulk Update" />
        <SKUTable />
      </Stack>
    </Box>
  );
};

export default BulkAdd;
